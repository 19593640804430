<template>
  <div class="upload-container">
    <!-- <el-button
      :style="{ background: color, borderColor: color }"
      icon="el-icon-upload"
      size="mini"
      type="primary"
      @click="dialogVisible = true"
      >上传图片/视频
    </el-button> -->
    <el-upload
      ref="upload"
      :multiple="true"
      :show-file-list="false"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      class="editor-slide-upload"
      :action="actionPath"
      :data="postData"
    >
      <!-- :file-list="fileList" -->
      <!-- list-type="picture-card" -->
      <el-button
        :style="{ background: color, borderColor: color }"
        icon="el-icon-upload"
        size="mini"
        type="primary"
        >上传图片/视频
      </el-button>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <el-upload
        ref="upload"
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        :action="actionPath"
        :data="postData"
        list-type="picture-card"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";
import { setTimeout } from "timers";

export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      actionPath: "",
      baseUrl: "",
      postData: {},
    };
  },
  mounted() {
    getQiniuToken().then((response) => {
      console.log(response);
      this.actionPath = response.data.uploadUrl;
      this.baseUrl = response.data.baseUrl;
      this.postData.token = response.data.token;
    });
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every(
        (item) => this.listObj[item].hasSuccess
      );
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      if (!this.checkAllSuccess()) {
        this.$message(
          "请等待所有图片上传成功 或 出现了网络问题，请刷新页面重新上传！"
        );
        this.listObj = {};
        this.fileList = [];
        return;
      }
      console.log(arr);
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess(response, file, fileList) {
      // const uid = file.uid
      console.log(response, file, fileList);
      // let list =[]
      // fileList.forEach(item => {
      //   console.log(item);
      // });
      this.fileList.push({ url: this.baseUrl + "/" + response.key });
      console.log(this.fileList);
      this.$emit("successCBK", this.fileList);
      // setTimeout(() => {
      // });
      // const objKeyArr = Object.keys(this.listObj);
      // console.log(file)
      // console.log(objKeyArr)
      // console.log(this.listObj)
      // console.log(uid)
      // for (let i = 0, len = objKeyArr.length; i < len; i++) {
      //   if (this.listObj[objKeyArr[i]].uid === uid) {
      //     this.listObj[objKeyArr[i]].url = this.baseUrl + '/' + response.key
      //     this.listObj[objKeyArr[i]].hasSuccess = true
      //     console.log(this.baseUrl + '/' + response.key);
      //     return
      //   }
      // }
      // console.log('这个')
      // console.log(objKeyArr)
      // let uid;
      // objKeyArr.forEach((val) => {
      //   uid = file.uid;
      //   setTimeout(() => {
      //     if (this.listObj[val].uid === uid) {
      //       this.listObj[val].hasSuccess = true;
      //       this.listObj[val].url = this.baseUrl + "/" + response.key;
      //       console.log(this.baseUrl + "/" + response.key);
      //       return;
      //     }
      //   }, 500);
      // });
    },
    handleRemove(file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload(file) {
      const _self = this;
      const _URL = window.URL || window.webkitURL;
      const fileName = file.uid;
      this.postData.key = guid() + "." + file.type.split("/")[1];
      console.log(this.postData.key, "============key");
      this.listObj[fileName] = {};
      this.fileList = [];
      // this.$refs['upload'].clearFiles();
      // clearFiles()
      // const img = new Image()
      // img.src = _URL.createObjectURL(file)
      // img.onload = function() {
      _self.listObj[fileName] = {
        hasSuccess: false,
        uid: file.uid,
        width: this.width,
        height: this.height,
      };
      // }
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  /deep/ .el-upload--picture-card {
    width: 100%;
  }
}
</style>

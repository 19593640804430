<template>
  <div class="richtext-container">
    <el-form :model="form" ref="form" label-width="150px">
      <el-form-item label="标题:">
        {{ form.title }}
      </el-form-item>
      <el-form-item label="内容:">
        {{ form.content }}
      </el-form-item>
      <el-form-item label="类型:">
        <el-tag v-if="form.type == 1" type="success">图文</el-tag>
        <el-tag v-else type="danger">视频</el-tag>
        <!-- {{ form.type==0?'视频':"图文" }} -->
      </el-form-item>

      <el-form-item v-if="form.images.length > 0">
        <el-image
          style="width: 400px; height: 200px;margin-right:10px"
          v-for="(item, i) in form.images"
          :key="i"
          :src="item"
          :preview-src-list="form.images"
        >
        </el-image>
      </el-form-item>
      <el-form-item v-if="form.video">
        <video
          :src="form.video"
          class="video"
          autoplay="true"
          controls="true"
          ref="videoPreview"
        ></video>
        <!-- <img
          v-for="(item,i) in form.images"
          :key="i"
          :src="item"
          alt=""
          class="image"
        /> -->
      </el-form-item>

      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      btnLoading: false,
      form: {},
    };
  },
  created() {
    this.getDetail(this.$route.query.id);
  },
  methods: {
    getDetail(id) {
      request({
        url: "/api/backend/post/detail",
        method: "get",
        params: {
          id,
        },
      }).then((response) => {
        this.form = response.data;
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/settings/store",
            method: "post",
            data: this.form,
          })
            .then(() => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
}
.image {
  width: 200px;
  height: 120px;
  margin-right: 30px;
}
.video {
  width: 500px;
  height: 300px;
}
</style>
